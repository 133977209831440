import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import Print from 'vue3-print-nb'
import { createPinia } from 'pinia'

const app = createApp(App);
app.use(router).use(ElementPlus, {locale: zhCn, }).use(createPinia()).use(Print).mount('#app')

// app.config.globalProperties.$axios = axios;